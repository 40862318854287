import {Component} from 'react';
import logo from "./logo.png";
import axios from 'axios';
import Navbar from "./nav2.component"
const Food = props => (
    <tr>
        <td> {props.name} </td>
        <td> {props.nutrition}</td>
        <td>{props.level}</td>
    </tr>
)

export default class Allergy extends Component {
    constructor(props) {
        super(props);
        this.allergyList = this.allergyList.bind(this);

        this.state = {
            allergens: [],
        }

    }

   componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL+"/allergies")
        .then(response => {
            this.setState({ allergens: response.data });
        })
        .catch((error) => {
            console.log(error);
        });
    }

    allergyList() {
        return this.state.allergens.map(allergen => {
            var leveli = "";
            if(allergen.level === 1)
                leveli = "High Risk: Do Not Use"
            else if (allergen.level === 2)
                leveli = "Low Risk: Use in Small Quantities";
            else if (allergen.level === 3)
                leveli = "Not Preferred"
            return <Food name={allergen.name} level={leveli} nutrition={allergen.nutrition} />
        });
    }

    render(){
        return(<div className="container-fluid p-3 my-3 bg-dark text-white">
        <Navbar />   
        <title>Adit's Allergies</title>
    <img className="text-center img-fluid w-25" align="middle" src={logo} alt = "Adit's Literature Website Logo" />
    <table className="table table-responsive-sm table-striped table-dark">
    <thead>
    <tr><td className="text-center" align="middle">
    <h1><b><i>Adit's Literature Website</i></b></h1></td>
    </tr>
    <tr>   <td className="text-center"> <h3>Adit's Allergy List</h3>   </td>
    </tr>
    <tr><td>Note: It is completely fine if allergens considered "Not Preferred" are included in the food cooked for Adit.</td></tr>
    </thead>
    </table>
    <table className="table table-responsive-sm table-striped table-dark">
    <thead>
    <tr>
        <th> Allergen </th>
        <th > Food Group </th>
        <th > Severity </th>
        {/* <th > Actions </th> */}
    </tr>
    </thead>
    <tbody>
    {this.allergyList()}
    </tbody>
    </table>
    <table className="table table-responsive-sm table-striped table-dark">
<thead className="text-center">
<tr><td><h4>If you've made it to the bottom of the list, you probably are wondering what can Adit eat?</h4></td></tr>
</thead><tbody>
<tr> <td> He can eat all grains, fats, cheeses, and most vegetables.</td></tr>
<tr> <td> In practice he can still have common world cusines such as <strong>Italian, Mediteranean, American and South Asian cuisine. </strong></td></tr>
<tr> <td> His favorite foods include: <strong>Paneer Tikka, Pizza, Pesto Pasta, Dosa, Quesadillas, and Kathi Rolls. </strong></td></tr>
</tbody>
    </table>   
    </div>
        );
    }
}