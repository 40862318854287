import { Component } from 'react';
import './nav2.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class Navbar extends Component {
    state = {
        isOpen: false,
    };

    toggleNav = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        const { isOpen } = this.state;

        return (
            <>
                <button className="nav-toggle" onClick={this.toggleNav}>
                ☰
                </button>

                <div className={`fullscreen-nav ${isOpen ? 'open' : ''}`}>
                    <button className="close-btn" onClick={this.toggleNav}>
                        ×
                    </button>
                    
                    <a href="/" className="home-icon">
                        <span className="nav-icon">🏠</span>
                    </a>

                    <nav className="nav-links">
                        <a href="/provoke" className="nav-item">
                            <span className="nav-icon">🔥</span>
                            <span className="nav-title">Provoke</span>
                            <span className="nav-description">Challenge your thoughts</span>
                        </a>
                        <a href="/professional" className="nav-item">
                            <span className="nav-icon">💼</span>
                            <span className="nav-title">Professional</span>
                            <span className="nav-description">Explore your career path</span>
                        </a>
                        <a href="/blog" className="nav-item">
                            <span className="nav-icon">📝</span>
                            <span className="nav-title">Blog</span>
                            <span className="nav-description">Read our latest posts</span>
                        </a>
                        <a href="/recipies" className="nav-item">
                            <span className="nav-icon">🍴</span>
                            <span className="nav-title">Recipes</span>
                            <span className="nav-description">Discover new dishes</span>
                        </a>
                        {/* <a href="/allergies" className="nav-item">
                            <span className="nav-icon">⚠️</span>
                            <span className="nav-title">Allergy List</span>
                            <span className="nav-description">Check your allergens</span>
                        </a> */}
                    </nav>
                </div>
            </>
        );
    }
}
