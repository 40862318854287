import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import axios from 'axios';

const API_KEY = 'AIzaSyC3p9gkZs35wbXQ7YX2tcjVDmS53eyFHtI'; // Replace with your YouTube Data API key

const raagData = {
  morning: { raag: 'Bhairav' },
  afternoon: { raag: 'Shuddh Sarang' },
  evening: { raag: 'Yaman' },
  night: { raag: 'Darbari Kanada' },
};

const getRaagForTime = () => {
  const hour = new Date().getHours();
  if (hour >= 5 && hour < 12) return 'morning';
  if (hour >= 12 && hour < 17) return 'afternoon';
  if (hour >= 17 && hour < 21) return 'evening';
  return 'night';
};

function RaagPlayerVisualizer() {
  const [currentRaag, setCurrentRaag] = useState(null);
  const [preFetchedIds, setPreFetchedIds] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playerInstance, setPlayerInstance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0); // Total duration of the current video in seconds
  const [currentTime, setCurrentTime] = useState(0); // Current playback time in seconds

  useEffect(() => {
    const fetchInitialVideos = async () => {
      try {
        setIsLoading(true);
        const timePeriod = getRaagForTime();
        const raag = raagData[timePeriod]?.raag;

        if (!raag) throw new Error('Raag data is missing for the current time period.');

        setCurrentRaag({ raag });

        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=100&q=${encodeURIComponent(
            raag
          )}&key=${API_KEY}`
        );

        const items = response.data.items;
        if (items && items.length > 0) {
          const videoIds = items.map((item) => ({
            id: item.id.videoId,
            title: item.snippet.title,
            channel: item.snippet.channelTitle,
            duration: null, // Placeholder for duration
          }));
          setPreFetchedIds(videoIds);
          setCurrentVideoIndex(0);
        } else {
          throw new Error('No videos found for the current raag.');
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
        setCurrentRaag(null);
        setPreFetchedIds([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialVideos();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (playerInstance && isPlaying) {
        setCurrentTime(playerInstance.getCurrentTime());
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [playerInstance, isPlaying]);

  const onPlayerReady = (event) => {
    const duration = event.target.getDuration();
    setVideoDuration(duration);
    setPlayerInstance(event.target);
    setIsPlaying(true);
    event.target.playVideo();
  };

  const onPlayerEnd = () => {
    // Automatically skip to the next video when the current one ends
    skipForward();
  };

  const togglePlayPause = () => {
    if (playerInstance) {
      if (isPlaying) {
        playerInstance.pauseVideo();
      } else {
        playerInstance.playVideo();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const skipForward = () => {
    if (isLoading || currentVideoIndex >= preFetchedIds.length - 1) return;

    const nextIndex = currentVideoIndex + 1;
    setCurrentVideoIndex(nextIndex);
    setIsPlaying(false);
    setCurrentTime(0);
  };

  const skipBackward = () => {
    if (isLoading || currentVideoIndex === 0) return;

    const prevIndex = currentVideoIndex - 1;
    setCurrentVideoIndex(prevIndex);
    setIsPlaying(false);
    setCurrentTime(0);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div style={{ height: '100vh', width: '100vw', backgroundColor: '#121212', position: 'relative' }}>
      {isLoading ? (
        <div style={{ color: 'white', textAlign: 'center', marginTop: '20%' }}>
          <h1>Loading Raag...</h1>
          <div
            className="spinner"
            style={{
              margin: '20px auto',
              width: '50px',
              height: '50px',
              border: '5px solid white',
              borderTop: '5px solid #6a5acd',
              borderRadius: '50%',
              animation: 'spin 1s linear infinite',
            }}
          ></div>
        </div>
      ) : (
        <>
          <YouTube
            videoId={preFetchedIds[currentVideoIndex]?.id}
            opts={{ height: '0', width: '0', playerVars: { autoplay: 1 } }}
            onReady={onPlayerReady}
            onEnd={onPlayerEnd} // Handle autoplay when video ends
          />

          <div style={{ color: 'white', textAlign: 'center', padding: '20px' }}>
            <h1>Raag: {currentRaag?.raag}</h1>
            {preFetchedIds[currentVideoIndex] && (
              <>
                <h2>{preFetchedIds[currentVideoIndex].title}</h2>
                <p>Channel: {preFetchedIds[currentVideoIndex].channel}</p>
              </>
            )}
            <div style={{ margin: '10px 0' }}>
              <span>{formatTime(currentTime)}</span> / <span>{formatTime(videoDuration)}</span>
            </div>
            <div
              style={{
                height: '5px',
                width: '80%',
                background: '#6a5acd',
                margin: '10px auto',
                borderRadius: '5px',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: `${(currentTime / videoDuration) * 100}%`,
                  background: 'white',
                }}
              ></div>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
            <button
              style={{
                padding: '10px 20px',
                fontSize: '1rem',
                background: '#6a5acd',
                border: 'none',
                color: 'white',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={skipBackward}
              disabled={currentVideoIndex === 0}
            >
              Previous
            </button>

            <button
              style={{
                padding: '10px 20px',
                fontSize: '1rem',
                background: '#6a5acd',
                border: 'none',
                color: 'white',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={togglePlayPause}
            >
              {isPlaying ? 'Pause' : 'Play'}
            </button>

            <button
              style={{
                padding: '10px 20px',
                fontSize: '1rem',
                background: '#6a5acd',
                border: 'none',
                color: 'white',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={skipForward}
              disabled={currentVideoIndex >= preFetchedIds.length - 1}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default RaagPlayerVisualizer;
