import React,{Component} from 'react';
// import {Helmet} from "react-helmet";
// import headshot from "../headshot.jpg"; 
import headshot from "./headshot1.jpg"; 
import "./professional.css";
import Navbar from "../nav2.component"
import resume from "./Adit_Mehta.resume.json";
// import Typist from "react-typist";
import resumepdf from "./resume.pdf"

const Experience = props => (
    <tr className="row">
                <td className="details" >
                <h4 className="header">{props.exp.name}</h4>
                <h3 className="role">{props.exp.position}</h3>
                <p className="time">{props.exp.startDate} : {props.exp.endDate}</p>
                <p className="descript">{props.exp.summary}</p>
                </td>
    </tr>
)

const Skill = props => (
    <td className="s"> {props.skill.name}</td>
)

const Education = props => (
    <tr className="row">
                <td className="details" >
                <h4 className="header">{props.edu.studyType}</h4>
                <h3 className="role">{props.edu.institution}</h3>
                <p className="time">{props.edu.startDate} : {props.edu.endDate}</p>
                <p className="descript"> {props.edu.area}</p>
                </td>
    </tr>
)

export default class Home extends Component {
    constructor (props){
        super(props);
        this.getExperience = this.getExperience.bind(this);
        // this.getEducation = this.getEducation.bind(this);
        
    };

    getExperience() {
        return resume.work.map((exp,index) => {
            return <Experience exp={exp}  key={"exp"+index}/>
        });
    }

    getEducation() {
        return resume.education.map((edu,index) => {
            return <Education edu={edu} key={"edu"+index} />
        });
    }

    getSkill() {
        return resume.skills.map((skill,index) => {
            return <Skill skill={skill} key={"skill"+index}  />
        });
    }

    render(){
        return(<div className="container-fluid px-0">
            <title>Adit's Resume</title>
            <Navbar/>
        <div className="frame">
        {/* <Helmet>
        <script type="text/javascript" src="https://platform.linkedin.com/badges/js/profile.js" async defer ></script>
        </Helmet> */}
        <h2 className="head" >Hi I'm {resume.basics.name}! </h2> 
        
        <div className="subhead">Leader | Planner | Storyteller </div>

        <div className="headshot"><a href={resumepdf}><img className="proprofile" src={headshot} alt=""></img></a>
        <p> Click my headshot to download my resume.</p>
        </div>
        {/* <Typist> */}
        <p className="abt"> {resume.basics.summary} </p>
        {/* </Typist> */}
        <div className="scroll">Scroll down to learn more about me!</div>
        </div>
        <div className="resume">
        <div className="resume2">
        <div className="sec">Experience</div>
        <table className="restab">
            <tbody>
                {this.getExperience()}
            </tbody>
        </table>
        <div className="sec">Education</div>
        <table className="restab">
            <tbody>
                {this.getEducation()}
            </tbody>
        </table>
        <div className="sec">Skills</div>
        <table className="table sdb">
            <tbody>
                <tr>
                {this.getSkill()}
                </tr>
            </tbody>
        </table>
        </div>
        </div>
        <div className="profooter">
            To contact me email <a href="mailto:mail@aditmehta.com">mail@aditmehta.com</a>!
           
        </div>
    </div>);
    }

    }